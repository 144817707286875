var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"mt-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('dashboard-card',{attrs:{"title":"Desarrollo saldo bancario del mes"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQueries([
                {category: 'currentMonth', field: 'dailyAccountBalance', queryMethod: _vm.getDailyAccountBalance, queryFilter: _vm.currentMonthFilters.startEndDate},
                {category: 'prevMonth', field: 'dailyAccountBalance', queryMethod: _vm.getDailyAccountBalance, queryFilter: _vm.prevMonthFilters.startEndDate},
                {category: 'currentMonth', field: 'dailyStock', queryMethod: _vm.getDailyStock, queryFilter: _vm.currentMonthFilters.dailyStock},
                {category: 'prevMonth', field: 'dailyStock', queryMethod: _vm.getDailyStock, queryFilter: _vm.prevMonthFilters.dailyStock},
              ])
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQueries([\n                {category: 'currentMonth', field: 'dailyAccountBalance', queryMethod: getDailyAccountBalance, queryFilter: currentMonthFilters.startEndDate},\n                {category: 'prevMonth', field: 'dailyAccountBalance', queryMethod: getDailyAccountBalance, queryFilter: prevMonthFilters.startEndDate},\n                {category: 'currentMonth', field: 'dailyStock', queryMethod: getDailyStock, queryFilter: currentMonthFilters.dailyStock},\n                {category: 'prevMonth', field: 'dailyStock', queryMethod: getDailyStock, queryFilter: prevMonthFilters.dailyStock},\n              ])\n            }"}],attrs:{"records":_vm.chartData.currentMonth.dailyAccountBalance,"prev-month-records":_vm.chartData.prevMonth.dailyAccountBalance,"path-to-category":['bankAccount'],"path-to-date":['date'],"dates":_vm.dates,"data-transformer":_vm.transformDailyBalance,"path-to-value":['accountBalance'],"sort-legends":false,"total-sufix":"prom./día","label-sufix":"prom./día","display-means":"","is-currency":"","show-prev-month":""}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('dashboard-card',{attrs:{"title":"Desarrollo saldo bancario del año"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQueries([
                {category: 'lastYear', field: 'dailyAccountBalance', queryMethod: _vm.getDailyAccountBalance, queryFilter: _vm.lastYearFilters.startEndDate},
                {category: 'lastYear', field: 'dailyStock', queryMethod: _vm.getDailyStock, queryFilter: _vm.lastYearFilters.dailyStock},
              ])
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQueries([\n                {category: 'lastYear', field: 'dailyAccountBalance', queryMethod: getDailyAccountBalance, queryFilter: lastYearFilters.startEndDate},\n                {category: 'lastYear', field: 'dailyStock', queryMethod: getDailyStock, queryFilter: lastYearFilters.dailyStock},\n              ])\n            }"}],attrs:{"records":_vm.chartData.lastYear.dailyAccountBalance,"prev-month-records":[],"path-to-category":['bankAccount'],"path-to-date":['date'],"null-zero-values":true,"dates":_vm.lastYearDates,"is-currency":true,"monthly":true,"mean-by-count":true,"show-prev-month":false,"data-transformer":_vm.transformDailyBalance,"path-to-value":['accountBalance'],"display-means":true,"sort-legends":false,"total-sufix":"prom./mes","label-sufix":"prom./mes"}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('dashboard-card',{attrs:{"title":"Desarrollo financiero mensual"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQueries([
                {category: 'currentMonth', field: 'dailyAccountBalance', queryMethod: _vm.getDailyAccountBalance, queryFilter: _vm.currentMonthFilters.startEndDate},
                {category: 'prevMonth', field: 'dailyAccountBalance', queryMethod: _vm.getDailyAccountBalance, queryFilter: _vm.prevMonthFilters.startEndDate},
                {category: 'currentMonth', field: 'dailyStock', queryMethod: _vm.getDailyStock, queryFilter: _vm.currentMonthFilters.dailyStock},
                {category: 'prevMonth', field: 'dailyStock', queryMethod: _vm.getDailyStock, queryFilter: _vm.prevMonthFilters.dailyStock},
              ])
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQueries([\n                {category: 'currentMonth', field: 'dailyAccountBalance', queryMethod: getDailyAccountBalance, queryFilter: currentMonthFilters.startEndDate},\n                {category: 'prevMonth', field: 'dailyAccountBalance', queryMethod: getDailyAccountBalance, queryFilter: prevMonthFilters.startEndDate},\n                {category: 'currentMonth', field: 'dailyStock', queryMethod: getDailyStock, queryFilter: currentMonthFilters.dailyStock},\n                {category: 'prevMonth', field: 'dailyStock', queryMethod: getDailyStock, queryFilter: prevMonthFilters.dailyStock},\n              ])\n            }"}],attrs:{"records":_vm.bankAccountsData.currentMonth,"prev-month-records":_vm.bankAccountsData.prevMonth,"path-to-date":['date'],"dates":_vm.dates,"is-currency":true,"data-transformer":_vm.transformDailyBalance,"path-to-value":['accountBalance'],"path-to-category":['type'],"display-means":true,"sort-legends":false,"total-sufix":"prom./día","label-sufix":"prom./día","show-prev-month":""}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('dashboard-card',{attrs:{"title":"Desarrollo financiero anual"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQueries([
                {category: 'lastYear', field: 'dailyAccountBalance', queryMethod: _vm.getDailyAccountBalance, queryFilter: _vm.lastYearFilters.startEndDate},
                {category: 'lastYear', field: 'dailyStock', queryMethod: _vm.getDailyStock, queryFilter: _vm.lastYearFilters.dailyStock},
              ])
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQueries([\n                {category: 'lastYear', field: 'dailyAccountBalance', queryMethod: getDailyAccountBalance, queryFilter: lastYearFilters.startEndDate},\n                {category: 'lastYear', field: 'dailyStock', queryMethod: getDailyStock, queryFilter: lastYearFilters.dailyStock},\n              ])\n            }"}],attrs:{"records":_vm.bankAccountsData.lastYear,"prev-month-records":[],"path-to-date":['date'],"dates":_vm.lastYearDates,"data-transformer":_vm.transformDailyBalance,"path-to-value":['accountBalance'],"path-to-category":['type'],"display-means":"","sort-legends":"","monthly":"","show-prev-month":false,"null-zero-values":"","total-sufix":"prom./mes","label-sufix":"prom./mes"}})]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }